import React from "react";

import {
  TranslatedInfo,
  Translations,
  websiteLanguages,
} from "../../generic/i18n";
import { rahnaGhazallKhaleqzoy } from "./employees/rahna-ghazall-khaleqzoy";
import { olgaSchaefer } from "./employees/olgaSchaefer";
import { drWajmaMangal } from "./employees/drWajmaMangal";
import { elizabethHincapieH } from "./employees/elizabethHHincapie";
import { gretaNeubauerKonradies } from "./employees/gretaNeuerbauerKonradies";
import { annaLenaHarth } from "./employees/anna-lena-harth";
import { torbenSchwarzer } from "./employees/torbenSchwarzer";
import { susanneLeist } from "./employees/susanneLeist";
import { laraSophieGessner } from "./employees/laraSophieGessner";
import { sohrabHashemi } from "./employees/sohrabHashemi";

export type Employee = {
  slug: string;
  displayName: string;
  linkedInLink?: string | undefined;
  title:
    | "legal-assistant-male"
    | "legal-assistant-female"
    | "lawyer-male"
    | "lawyer-female"
    | "lawyer-partner-female"
    | "lawyer-partner-male"
    | "office-manager-female"
    | "student-assistant-male"
    | "student-assistant-female"
    | "personal-assistant-female";
  isExternal?: boolean | undefined;
  subTitle?: string | undefined;
  secondTitle?: string | undefined;
  hasProfile?: boolean | undefined;
  languages: Language[];

  img: string;
  additionalQualifications?: string[] | undefined;
  awards?: string[] | undefined;
  translations?: Translations<EmployeeTranslatedInfo>[] | undefined;
  displayNameSuffix?: string | undefined;
};

export interface EmployeeTranslatedInfo {
  memberships?: string[] | undefined;
  awards?: string[] | undefined;
  publications?: Publication[] | undefined;
  expertTalks?: Publication[] | undefined;
  additionalQualifications?: string[] | undefined;
  certifications?: string[] | undefined;
  positions?: string[] | undefined;
}

export type Publication = {
  title: string;
  date: string;
  location?: string | undefined;
  link?: string | undefined;
};

export type Language = "deu" | "eng" | "pst" | "prs" | "rus" | "esp" | "fra";

export const employeeList: Employee[] = [
  drWajmaMangal,
  torbenSchwarzer,
  elizabethHincapieH,
  gretaNeubauerKonradies,
  annaLenaHarth,
  olgaSchaefer,
  sohrabHashemi,
  rahnaGhazallKhaleqzoy,
  laraSophieGessner,
];
